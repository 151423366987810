import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Order from "./views/Order.vue";
import Product from "./views/ProductPage.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/order",
      name: "order",
      component: Order,
      props: true,
    },
    {
      path: "/order/:groupName",
      name: "mgroup",
      component: Order,
      props: true,
    },
    {
      path: "/product/:productId",
      name: "productPage",
      component: Product,
      props: true,
    },
  ],
});
