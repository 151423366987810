<template>
  <div class="mt-1" v-if="total > 0">
    <BaseIcon name="shopping-cart" />
    ({{ itemsQuantity }} Artikel) Total: {{ total | currency }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "app-shoppingcart",
  computed: {
    ...mapGetters(["itemsQuantity", "taxes", "total"]),
  },
};
</script>
<style scoped>
.icon-wrapper {
  color: #ffffff;
  font-size: 10px;
}
</style>
