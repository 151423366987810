import axios from "axios";
import NProgress from "nprogress";

const apiClient = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  // Called on request
  NProgress.start();
  return config;
});

apiClient.interceptors.response.use((response) => {
  // Called on response
  NProgress.done();
  return response;
});

export default {
  getAllProducts(baseURL) {
    return apiClient.get(baseURL + "/Article");
  },

  startOrder(baseURL) {
    return apiClient.get(baseURL + "/StartOrder");
  },

  closeOrder(baseURL, cart_content) {
    return apiClient.post(baseURL + "/CloseOrder", cart_content);
  },

  getOrderState(baseURL, SessionID) {
    return apiClient.get(baseURL + "/OrderState/" + SessionID);
  },

  getArticleImageUrl(baseURL, id) {
    return baseURL + "/ArticleImage/" + id;
  },
};
