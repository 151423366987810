<template>
	<ul class="list-group cart">
		<li class="list-group-item">
			<b-row>
				<b-col class="col-md-12 p-0 text-center">
					<h4>{{ $t("Gesamtsumme") }}: {{ total | currency }}</h4>
				</b-col>
			</b-row>
		</li>
	</ul>
</template>

<script>
	import { mapGetters } from "vuex";

	export default {
		name: "app-cartsummary",
		computed: {
			...mapGetters(["itemsQuantity", "subtotal", "taxes", "total"]),
		},
	};
</script>

<style scoped></style>
