import Vue from "vue";
import "./assets/css/custom.scss";
import "./plugins/bootstrap-vue";
import VueCurrencyFilter from "vue-currency-filter";
import pluralize from "pluralize";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import VueBarcodeScanner from "vue-barcode-scanner";
import IdleVue from "idle-vue";
import axios from "axios";

axios.get("/assets/config.json").then((response) => {
  var currencySymbol = response.data.currencySymbol;
  var symbolSpacing = response.data.symbolSpacing;
  var idleTime = response.data.idleTime;
  var columns = response.data.columns;
  var rows = response.data.rows;

  Vue.use(VueCurrencyFilter, {
    symbol: currencySymbol,
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "back",
    symbolSpacing: symbolSpacing,
  });

  const eventsHub = new Vue();
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: idleTime,
    startAtIdle: false,
  });

  Vue.mixin({
    data: function() {
      return {
        get globalRows() {
          return rows;
        },
        get globalItemsPerRow() {
          return columns;
        },
      };
    },
  });
});

let options = {
  sound: true,
  soundSrc: "/assets/sound/ding.wav",
  sensitivity: 100,
};
Vue.use(VueBarcodeScanner, options);

const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.config.productionTip = false;

Vue.filter("pluralize", pluralize);

fetch("/assets/config.json")
  .then((res) => res.json())
  .then((config) => {
    new Vue({
      data() {
        return config;
      },
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  });
