<template>
	<div class="wrapper home-view">
		<router-link class="router-link" :to="{ name: 'order' }">
			<div class="container mb-5" @click="StartOrder()">
				<div class="row">
					<div class="col-lg-12">
						<div class="d-flex justify-content-center welcome">
							<div class="my-auto text-center">
								<h1 class="display-5">
									{{ $t("ZUM START BILDSCHIRM BERÜHREN") }}
								</h1>
								<LanguageWidget :width="80" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
	import LanguageWidget from "@/components/languageWidget";
	import DataService from "@/services/DataService";

	export default {
		name: "page-home",
		components: {
			LanguageWidget,
		},
		methods: {
			StartOrder() {
				DataService.startOrder(this.$root.baseUrl);
			},
		},
	};
</script>
