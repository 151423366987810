<template>
  <div id="navbar">
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand to="/">XENIA</b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item></b-nav-item>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
          <CartWidget />
        </b-nav-item>
        <b-nav-item>
          <LanguageWidget :width="30" />
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import CartWidget from "@/components/shopping-cart/CartWidget";
import LanguageWidget from "@/components/languageWidget";

export default {
  name: "app-header",
  components: {
    CartWidget,
    LanguageWidget
  }
};
</script>

<style scoped>
</style>