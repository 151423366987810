var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:([
    this.$route.name === 'home'
      ? {
          background:
            'linear-gradient(0deg,rgba(50,50,50, 1) 0%,rgba(24, 188, 156, 1) 50%,rgba(50,50,50, 1) 100%)',
        }
      : { background: '#FFF' },
    _vm.get_lang() === 'ar' ? { direction: 'rtl' } : { direction: 'ltr' },
  ]),attrs:{"id":"app"}},[_c('div',{staticClass:"content"},[_c('NavBar'),_c('div',{staticClass:"container pb-5 pt-4"},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),_c('Footer')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }