<template>
  <div>
    <div class="row px-2 mt-1">
      <div class="col-md-12 p-0">
        <div class="item-infoS">
          <div class="item-title m-0">
            <em
              ><b>{{ item.quantity }}</b></em
            >
            x {{ item.name }}
          </div>
          <div v-for="item in item.selectedOptions" :key="item.id">
            <div v-for="subItem in item.options" :key="subItem.id">
              <div class="item-sides p-0">
                incl. {{ subItem.optionQuantity }} x {{ subItem.name }} (@{{
                  subItem.addPrice | currency
                }})
              </div>
            </div>
          </div>
          <div class="item-total color p-0">
            Total: {{ item.totalPrice | currency }}
          </div>
        </div>
        <div class="item-optionsS">
          <RemoveFromCart :product="item" />
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import RemoveFromCart from "@/components/shopping-cart/RemoveFromCart";

export default {
  name: "app-shoppingdartitem",
  props: {
    item: Object,
  },
  components: { RemoveFromCart },
};
</script>

<style scoped>
</style>
