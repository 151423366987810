<template>
  <footer id="sticky-footer" class="bg-primary">
    <div class="container w-100 text-center">
      <span>Copyright &copy; 2020 XENIA</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
  props: {
    api_status: {}
  }
};
</script>

<style scoped></style>
