<template>
  <button type="button" class="close" aria-label="Remove" @click="removeFromCart(product)">
    <BaseIcon name="trash-2" />
  </button>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "app-removefromcart",
  props: {
    product: Object
  },
  methods: mapActions(["removeFromCart"])
};
</script>

<style scoped>
</style>
