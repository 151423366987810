<template>
  <div
    id="app"
    :style="[
      this.$route.name === 'home'
        ? {
            background:
              'linear-gradient(0deg,rgba(50,50,50, 1) 0%,rgba(24, 188, 156, 1) 50%,rgba(50,50,50, 1) 100%)',
          }
        : { background: '#FFF' },
      get_lang() === 'ar' ? { direction: 'rtl' } : { direction: 'ltr' },
    ]"
  >
    <div class="content">
      <NavBar />
      <div class="container pb-5 pt-4">
        <transition name="slide-fade" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Vue from "vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
  },
  created() {
    this.$root.languages.forEach(function (index) {
      if (index != "") {
        axios.get("assets/locale/" + index + ".json").then((response) => {
          Vue.i18n.add(index, response.data);
        });
      }
    });
    if (!Vue.i18n.locale()) {
      Vue.i18n.set(this.$root.defaultLanguage);
    }
  },
  methods: {
    get_lang() {
      return Vue.i18n.locale();
    },
    set_lang(value) {
      Vue.i18n.set(value);
    },
  },
};
</script>


