<template>
  <div>
    <div class="row px-2 my-1">
      <div class="col-md-12 p-0">
        <div class="item-image">
          <div class="productImage">
            <img
              class="w-100"
              v-bind:src="GetArticleImageUrl(item.id)"
              @error="replaceByDefault"
            />
          </div>
        </div>
        <div class="item-info">
          <div class="item-title m-0">
            <em
              ><b>{{ item.quantity }}</b></em
            >
            x {{ item.name }}
            <span class="item-sides">(@{{ item.price | currency }})</span>
          </div>
          <div v-for="item in item.selectedOptions" :key="item.id">
            <div v-for="subItem in item.options" :key="subItem.id">
              <div class="item-sides p-0">
                incl. {{ subItem.optionQuantity }} x
                {{ subItem.name }}
                (@{{ subItem.addPrice | currency }})
              </div>
            </div>
          </div>
          <div class="item-total color p-0">
            Total: {{ item.totalPrice | currency }}
          </div>
        </div>
        <div class="item-options">
          <RemoveFromCart :product="item" />
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import RemoveFromCart from "@/components/shopping-cart/RemoveFromCart";
import DataService from "@/services/DataService";

export default {
  name: "app-siext",
  props: {
    item: Object,
  },
  components: { RemoveFromCart },
  methods: {
    replaceByDefault(e) {
      e.target.src = "/assets/img/no_image.jpg";
    },
    GetArticleImageUrl(id) {
      //return "https://meeh.eu/xeno-data/img/" + id + ".jpg";
      return DataService.getArticleImageUrl(this.$root.baseUrl, id);
    },
  },
};
</script>

<style scoped>
</style>
