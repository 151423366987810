<template>
	<div>
		<div class="row">
			<div :class="'col-lg-' + 12 / globalItemsPerRow + ' px-1 py-1'" v-for="product in paginatedData" :key="product.id">
				<Product :product="product" />
			</div>
		</div>
		<div v-if="pageCount > 1" class="row">
			<div class="col-lg-12 text-center">
				<button class="btn btn-success" :disabled="pageNumber === 0" @click="prevPage">
					{{ $t("Zurück") }}
				</button>
				<button class="btn btn-success" :disabled="pageNumber >= pageCount - 1" @click="nextPage">
					{{ $t("Weiter") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import Product from "@/components/Product";

	export default {
		name: "app-productgroup",
		components: {
			Product,
		},
		props: {
			group: Array,
		},
		data() {
			return {
				pageNumber: 0,
			};
		},
		methods: {
			nextPage() {
				this.pageNumber++;
			},
			prevPage() {
				this.pageNumber--;
			},
		},
		computed: {
			pageCount() {
				let l = this.group.length,
					s = this.globalItemsPerRow * this.globalRows;
				return Math.ceil(l / s);
			},
			paginatedData() {
				const start = this.pageNumber * this.globalItemsPerRow * this.globalRows,
					end = start + this.globalItemsPerRow * this.globalRows;
				return this.group.slice(start, end);
			},
		},
	};
</script>
