<template>
  <div>
    <div class="row">
      <div
        class="col-lg-12 p-1"
        v-for="product in singleProduct"
        :key="product.id"
      >
        <singleProduct :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import singleProduct from "@/components/singleProduct";
import { mapActions, mapState } from "vuex";
export default {
  name: "page-product",
  components: {
    singleProduct,
  },
  created() {
    this.getProducts();
  },
  props: {
    productId: {
      type: Number,
    },
  },
  methods: {
    ...mapActions({ getProducts: "getProducts" }),
  },
  computed: {
    ...mapState({
      products: (state) => state.products.all,
    }),
    spGroupName() {
      return this.products.filter((item) => item.id == this.productId);
    },
    singleProduct() {
      var data = this.products.filter((item) => item.id == this.productId);
      var result = data.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.name).indexOf(obj.name) == pos;
      });
      return result;
    },
  },
};
</script>

<style scoped>
h1 {
  cursor: pointer;
}
</style>