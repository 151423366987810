<template>
	<div>
		<b-modal size="md" ref="scan-modal" id="scanModal" centered hide-header hide-footer no-close-on-backdrop>
			<div class="item-card-modal scanModal text-center mr-2">
				<div v-if="scanResult != 0">
					<barcode v-bind:value="scanResult"></barcode>
				</div>
				<h4>
					{{ scanProduct }}
					{{ $t(" wurde Ihrem Warenkorb hinzugefügt") }}
				</h4>
				<button class="btn btn-success" @click="rearm">
					{{ $t("Weiter") }}
				</button>
			</div>
		</b-modal>
		<div class="row">
			<div class="col-lg-2 px-1 mt-1">
				<div v-for="(item, index) in itemGroups" :key="index">
					<div class="group-card panel t1 mb-1" :class="item === groupName ? 'active' : ''" @click="updateProducts(item)">
						<div class="text-center pt-2">
							<h5 class="grtxt mb-0">{{ productGroupName(item) }}</h5>
						</div>
					</div>
				</div>
				<div class="group-card panel t1 mb-1" :class="groupName === 'all' ? 'active' : ''" @click="updateProducts('all')">
					<div class="text-center pt-2">
						<h5 class="grtxt mb-0">{{ $t("Alles") }}</h5>
					</div>
				</div>
			</div>
			<div class="col-lg-7">
				<div v-if="groupName === 'all'">
					<h3 class="color">{{ $t("Alles") }}</h3>
					<transition name="slide-fade" mode="out-in">
						<div>
							<productGroup :group="productGroupAll()" :key="componentKey" />
						</div>
					</transition>
				</div>
				<div v-else>
					<h3 class="color">{{ productGroupName(groupName) }}</h3>
					<transition name="slide-fade" mode="out-in">
						<productGroup :group="productGroup(groupName)" :key="componentKey" />
					</transition>
				</div>
			</div>
			<div class="col-lg-3 px-1 mt-1">
				<ShoppingCart />
			</div>
		</div>
	</div>
</template>

<script>
	import productGroup from "@/components/productGroup.vue";
	import ShoppingCart from "@/components/shopping-cart/ShoppingCart";
	import { mapState, mapActions } from "vuex";
	import Vue from "vue";
	import VueBarcode from "vue-barcode";

	export default {
		name: "page-order",
		components: {
			productGroup,
			ShoppingCart,
			barcode: VueBarcode,
		},
		data() {
			return {
				componentKey: 0,
				groupName: "all",
				scanResult: 0,
				scanProduct: 0,
			};
		},
		created() {
			this.$barcodeScanner.init(this.onBarcodeScanned);
			if (this.$route.params.grpNme) {
				this.groupName = this.$route.params.grpNme;
			}
		},
		destroyed() {
			this.$barcodeScanner.destroy();
		},
		methods: {
			...mapActions({
				addToCart: "addToCart",
				removeAllFromCart: "removeAllFromCart",
			}),

			productGroup(m) {
				return this.products.filter((item) => item.groupName === m);
			},

			productGroupAll() {
				return this.products;
			},

			productGroupName(m) {
				var lang = Vue.i18n.locale();
				var index = this.$root.languages.findIndex((x) => x === lang);
				var gn = this.products.find((item) => item.groupName === m);
				if (index == 1 && gn.groupName1) {
					return gn.groupName1;
				} else if (index == 2 && gn.groupName2) {
					return gn.groupName2;
				} else if (index == 3 && gn.groupName3) {
					return gn.groupName3;
				} else {
					return m;
				}
			},
			updateProducts(val) {
				this.groupName = val;
				this.componentKey += 1;
			},
			onBarcodeScanned(barcode) {
				var prodByEan = function(gesuchteEan, alleArtikel) {
					let gesucht = alleArtikel.find((item) => {
						let gefunden = item.ean.find((ean) => ean === gesuchteEan);
						return gefunden ? item : null;
					});
					return gesucht;
				};
				var product = [prodByEan(barcode, this.products)];
				product[0].totalPrice = product[0].price;
				product[0].selectedOptions = [];
				product[0].productQuantity = 1;

				this.addToCart(product[0]);
				this.scanResult = product[0].ean[0];
				this.scanProduct = product[0].name;
				this.$refs["scan-modal"].show();
				this.$barcodeScanner.destroy();
			},
			rearm() {
				this.$barcodeScanner.init(this.onBarcodeScanned);
				this.$refs["scan-modal"].hide();
			},
		},
		computed: {
			...mapState({
				products: (state) => state.products.all,
			}),
			itemGroups() {
				return [...new Set(this.products.map(({ groupName }) => groupName))];
			},
			isIdle() {
				return this.$store.state.idleVue.isIdle;
			},
		},
		watch: {
			isIdle(status) {
				if (status == true) {
					this.removeAllFromCart();
					this.$router.push({ name: "home" });
				}
			},
		},
	};
</script>
<style scoped>
	.grtxt {
		padding-left: 5px;
		width: 152px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
