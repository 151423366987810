<template>
  <div class="langSwitcher">
    <span v-for="(item, index) in this.$root.languages" :key="index">
      <img
        v-if="item != '' && item != get_lang()"
        :src="'/assets/img/' + item + '.png'"
        :width="width"
        tabindex="-1"
        @click="set_lang(item)"
      />
    </span>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "app-languagewidget",
  props: {
    width: Number,
  },
  methods: {
    get_lang() {
      return Vue.i18n.locale();
    },
    set_lang(value) {
      Vue.i18n.set(value);
    },
  },
};
</script>

<style>
</style>