<template>
	<router-link class="router-link" :to="{ name: 'productPage', params: { productId: product.id } }">
		<div class="item-card -shadow rounded">
			<div v-if="product.promotion" class="ribbon ribbon-bottom-right">
				<span>{{ $t("ANGEBOT") }}</span>
			</div>
			<div class="productIcon">
				<BaseIcon name="target" />
			</div>
			<div class="productName">
				<span v-if="translate == 1 && product.name1">
					{{ product.name1 }}
				</span>
				<span v-else-if="translate == 2 && product.name2">
					{{ product.name2 }}
				</span>
				<span v-else-if="translate == 3 && product.name3">
					{{ product.name3 }}
				</span>
				<span v-else>{{ product.name }}</span>
			</div>

			<div class="productImage">
				<img v-if="GetArticleImageUrl(product.id)" v-bind:src="GetArticleImageUrl(product.id)" @error="replaceByDefault" />
			</div>
			<div class="row">
				<div class="col-lg-5 text-left">
					<div v-if="product.calories > 0">
						<span class="productCalories">{{ product.calories }}Cal</span>
					</div>
				</div>
				<div class="productPrice col-lg-7 text-right">
					<h4 class="m-0">{{ (product.price + OptionPrice()) | currency }}</h4>
				</div>
			</div>

			<span class="ausverkauft" v-if="product.soldOut">
				{{ $t("AUSVERKAUFT") }}
			</span>
		</div>
	</router-link>
</template>

<script>
	import DataService from "@/services/DataService";
	import Vue from "vue";

	export default {
		name: "app-product",
		props: {
			product: Object,
		},
		data() {
			return {
				productOptions: Object,
				productInformation: "",
			};
		},
		created: function() {
			var creOpt = this.product.optionGroups;
			this.productOptions = [];
			if (creOpt) {
				for (var i = 0; i < creOpt.length; i++) {
					this.productOptions.push({
						id: creOpt[i].id,
						name: creOpt[i].name,
						nameTranslated: creOpt[i].nameTranslated,
						multiselect: creOpt[i].multiselect,
						options: [],
					});
					for (var s = 0; s < creOpt[i].options.length; s++) {
						this.productOptions[i].options.push({
							id: creOpt[i].options[s].id,
							name: creOpt[i].options[s].name,
							name1: creOpt[i].options[s].name1,
							name2: creOpt[i].options[s].name2,
							name3: creOpt[i].options[s].name3,
							addPrice: creOpt[i].options[s].addPrice,
							optionQuantity: 0,
						});
					}
				}
			}
		},
		computed: {
			translate() {
				var lang = Vue.i18n.locale();
				var index = this.$root.languages.findIndex((x) => x === lang);
				return index;
			},
		},
		methods: {
			productInfo(val) {
				var lang = Vue.i18n.locale();
				var i = this.companyData.languages.findIndex((x) => x === lang);
				var info = val.split("|");
				var infotext = info[i];
				if (val.indexOf("|") > 0) {
					if (infotext.length > 80) {
						return infotext.substring(0, 80) + " ...";
					} else {
						return infotext;
					}
				} else {
					return val;
				}
			},
			GetArticleImageUrl(id) {
				//return "https://meeh.eu/xeno-data/img/" + id + ".jpg";
				return DataService.getArticleImageUrl(this.$root.baseUrl, id);
			},
			OptionPrice() {
				var optionPrice = 0;
				if (this.productOptions.length) {
					for (var i = 0; i < this.productOptions.length; i++) {
						var mslct = this.productOptions[i].multiselect;
						if (mslct == false) {
							optionPrice += this.productOptions[i].options[0]["addPrice"];
						}
					}
				}
				return optionPrice;
			},

			replaceByDefault(e) {
				e.target.src = "/assets/img/no_image.jpg";
			},
		},
	};
</script>
