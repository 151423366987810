import DataService from "@/services/DataService.js";
import axios from "axios";

export const getProducts = ({ commit }) => {
  axios.get("/assets/config.json").then((response) => {
    DataService.getAllProducts(response.data.baseUrl).then((response) => {
      commit("RECEIVE_PRODUCTS", response.data);
    });
  });
};

export const addToCart = ({ commit }, cartItem) => {
  if (cartItem.soldOut == false) {
    commit("ADD_TO_CART", cartItem);
  }
};

export const removeFromCart = ({ commit }, cartItem) => {
  commit("REMOVE_FROM_CART", cartItem);
};

export const removeAllFromCart = ({ commit }) => {
  commit("REMOVE_ALL_FROM_CART");
};
