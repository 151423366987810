<template>
	<div class="cart -shadow rounded">
		<div v-if="items.length" class="p-2">
			<BaseIcon name="shopping-cart" />
			<h5>{{ $t("Cart") }}</h5>
			<ShoppingCartItem v-for="(item, index) in items" :item="item" :key="index" />
		</div>
		<div v-else class="p-2">
			<BaseIcon name="shopping-cart" />
			<h5>{{ $t("Warenkorb Leer") }}</h5>
		</div>
		<ShoppingCartSummary />
		<div v-if="items.length" class="mt-3 text-center">
			<button class="btn btn-success" @click="emptyCart">
				{{ $t("Leeren") }}
			</button>
			<button class="btn btn-success" @click="showModal">
				{{ $t("Bezahlen") }}
			</button>
		</div>

		<b-modal size="lg mh-50" ref="checkout-modal" id="checkout-modal" no-close-on-backdrop centered hide-header hide-footer>
			<div class="cart-content">
				<div class="cart">
					<div class="row">
						<div class="col-lg-6">
							<div id="card_name">
								<BaseIcon name="target" />
								<h2>{{ $t("Cart") }}</h2>
							</div>
							<ShoppingCartItemExt v-for="(item, index) in items" :item="item" :key="index" />
						</div>
						<div class="col-lg-6">
							<ShoppingCartSummary />
							<div class="ShoppingCartSummary">
								<div v-show="payment1" class="text-center my-auto">
									<h3>
										{{ $t("Bitte Wählen Sie Ihre bevorzugte Zahlungsmethode") }}
									</h3>
								</div>
								<div v-show="payment2" class="text-center my-auto">
									<h3>{{ $t(payType) }}</h3>
									<p>
										<img class="mb-0 rotating" src="/assets/img/kunden_logo2.png" alt="Xenia" width="40" />
									</p>
									<h3>{{ $t(info) }}</h3>
								</div>
								<div v-show="payment3" class="text-center my-auto">
									<h3>
										{{ $t("Bitte geben sie Ihre ZimmerNummer ein") }}
									</h3>
									<div>
										<b-form-input :value="input" class="input text-center mb-2" size="lg" @input="onInputChange" disabled></b-form-input>
										<SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="cart-button-wrapper">
					<div class="cart-buttons m-0 p-0" v-show="cart_buttons">
						<b-row class="mt-0">
							<div class="col-lg-5 text-left">
								<button class="btn btn-lg btn-success" @click="hideModal">
									{{ $t("Abbrechen") }}
								</button>
								<button class="btn btn-lg btn-success" @click="emptyCart">
									{{ $t("Leeren") }}
								</button>
							</div>
							<div v-if="items.length" class="col-lg-7 text-right">
								<button v-if="enable_creditCard" class="btn btn-lg btn-success" @click="buy((type = 'credit'))">
									{{ $t(name_creditCard) }}
								</button>
								<button v-if="enable_numberCard && enable_roomnumber == true" class="btn btn-lg btn-success" @click="checkRoomNumber()">
									{{ $t(name_numberCard) }}
								</button>
								<button v-if="enable_numberCard && enable_roomnumber == false" class="btn btn-lg btn-success" @click="buy((type = 'hotel'))">
									{{ $t(name_numberCard) }}
								</button>
							</div>
						</b-row>
					</div>

					<div class="cart-buttons m-0 p-0" v-show="payment3">
						<b-row class="mt-0">
							<div class="col-lg-6 text-left">
								<button class="btn btn-lg btn-success" @click="hideModal2">
									{{ $t("Abbrechen") }}
								</button>
							</div>
							<div class="col-lg-6 text-right">
								<button class="btn btn-lg btn-success" @click="buy((type = 'hotel'))">
									{{ $t("Bezahlen") }}
								</button>
							</div>
						</b-row>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import ShoppingCartItem from "@/components/shopping-cart/ShoppingCartItem";
	import ShoppingCartItemExt from "@/components/shopping-cart/ShoppingCartItemExt";
	import ShoppingCartSummary from "@/components/shopping-cart/ShoppingCartSummary";
	import DataService from "@/services/DataService";
	import SimpleKeyboard from "@/components/shopping-cart/keyboard";
	import { mapGetters, mapActions, mapState } from "vuex";

	export default {
		name: "app-shoppingcart",
		computed: {
			...mapState({ products: (state) => state.products.all }),
			...mapGetters({ items: "cartProducts" }),
			shopCart() {
				var scart = [];
				scart.push({
					payMethod: 1,
					RoomNumber: 0,
					posList: this.items.map((item) => {
						item = {
							articleId: item.id,
							quantity: item.quantity,
							singlePrice: item.price,
							variantList: item.selectedOptions.map((opt) => opt.options.map((x) => x.id)).join(),
							parent: true,
							name: item.name,
						};
						return item;
					}),
				});
				for (var i = 0; i < this.items.length; i++) {
					for (var s = 0; s < this.items[i].selectedOptions.length; s++) {
						for (var m = 0; m < this.items[i].selectedOptions[s].options.length; m++) {
							scart[0].posList.push({
								articleId: this.items[i].selectedOptions[s].options[m].id,
								quantity: this.items[i].selectedOptions[s].options[m].optionQuantity,
								singlePrice: this.items[i].selectedOptions[s].options[m].addPrice,
								variantList: this.items[i].id.toString(),
								parent: false,
								name: this.items[i].selectedOptions[s].options[m].name,
							});
						}
					}
				}
				scart = scart.map(function(e) {
					return JSON.stringify(e);
				});
				scart = scart.join(",");
				return scart;
			},
		},
		components: {
			ShoppingCartItem,
			ShoppingCartItemExt,
			ShoppingCartSummary,
			SimpleKeyboard,
		},
		data() {
			return {
				payment1: true,
				payment2: false,
				payment3: false,
				cart_buttons: true,
				payType: null,
				info: null,
				input: "",
				random_number: Math.floor(1000 + Math.random() * 9000),
				name_numberCard: this.$root.name_numberCard,
				name_creditCard: this.$root.name_creditCard,
				enable_numberCard: this.$root.enable_numberCard,
				enable_creditCard: this.$root.enable_creditCard,
				enable_roomnumber: this.$root.enable_roomnumber,
			};
		},
		created() {
			this.getProducts();
		},
		methods: {
			...mapActions({
				getProducts: "getProducts",
				removeAllFromCart: "removeAllFromCart",
			}),
			onChange(input) {
				this.input = input;
			},
			onKeyPress(button) {
				if (button === "{clear}") this.input = "";
			},
			onInputChange(input) {
				this.input = input.target.value;
			},
			emptyCart() {
				this.removeAllFromCart();
				this.hideModal();
			},
			checkRoomNumber() {
				this.payment1 = false;
				this.payment2 = false;
				this.cart_buttons = false;
				this.payment3 = true;
			},
			buy(type) {
				this.cart_buttons = false;
				this.payment1 = false;
				this.payment2 = true;
				this.payment3 = false;
				var cart_content = JSON.parse(this.shopCart);
				cart_content.RoomNumber = this.input;
				if (type === "hotel") {
					cart_content.payMethod = 2;
					this.payType = "Bezahlen mit " + this.name_numberCard;
				} else if (type === "credit") {
					cart_content.payMethod = 1;
					this.payType = "Bezahlen mit " + this.name_creditCard;
				}
				var status = this;
				var rtr = this;
				DataService.closeOrder(this.$root.baseUrl, cart_content).then((response) => {
					this.info = response.data.result;

					function checkOrder(SessionID) {
						DataService.getOrderState(rtr.$root.baseUrl, SessionID).then((response) => {
							if (response.data.resultCode != 2 && response.data.resultCode != 3) {
								setTimeout(checkOrder(SessionID), 1000);
							} else {
								setTimeout(() => {
									rtr.removeAllFromCart();
									rtr.$router.push({ name: "home" });
								}, 5000);
							}
							status.info = response.data.result;
						});
					}

					checkOrder(response.data.sessionId);
				});
			},
			showModal() {
				this.$bvModal.show("checkout-modal");
			},
			hideModal() {
				setTimeout(() => {
					this.$refs["checkout-modal"].hide();
				}, 300);
			},
			hideModal2() {
				setTimeout(() => {
					this.cart_buttons = true;
					this.payment1 = true;
					this.payment2 = false;
					this.payment3 = false;
					this.$refs["checkout-modal"].hide();
				}, 300);
			},
		},
	};
</script>
<style scoped></style>
