export const cartProducts = (state) => {
  return state.products.added.map(
    ({ id, totalPrice, selectedOptions, quantity }) => {
      const cartItem = state.products.all.find((product) => product.id === id);
      return {
        ...cartItem,
        totalPrice,
        selectedOptions,
        quantity,
      };
    }
  );
};

export const itemsQuantity = (state) => {
  return cartProducts(state).reduce((quantity, item) => {
    return quantity + item.quantity;
  }, 0);
};

export const total = (state) => {
  const sum = cartProducts(state).reduce((subtotal, item) => {
    return subtotal + item.totalPrice;
  }, 0);

  return sum;
};

export const taxes = (state) => (total(state) / 1.19) * 0.19;
